
import { computed, defineComponent, onMounted, ref } from 'vue'
import { marked } from 'marked'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { GlobalDataProps, PostProps } from '../store'
import UserProfile from '../components/UserProfile.vue'
import Modal from '../components/Modal.vue'
import createMessage from '../components/createMessage'

export default defineComponent({
  name: 'post-detail',
  components: {
    // UserProfile,
    Modal
  },
  setup() {
    const store = useStore<GlobalDataProps>()
    const route = useRoute()
    const router = useRouter()
    const modalIsVisible = ref(false)
    const currentId = route.params.id
    onMounted(() => {
      //  store.dispatch('fetchPost', currentId)
    })
    const currentPost = computed<PostProps>(() => store.getters.getCurrentPost(currentId))
    const currentHTML = computed(() => {
      if (currentPost.value && currentPost.value.desc) {
        const {
          isHTML,
          desc
        } = currentPost.value
        return isHTML ? desc : marked.parse(desc)
      } else {
        return ''
      }
    })
    const showEditArea = computed(() => {
      // const { isLogin, _id } = store.state.user
      // if (currentPost.value && currentPost.value.author && isLogin) {
      //   const postAuthor = currentPost.value.author as UserProps
      //   return postAuthor._id === _id
      // } else {
      //   return false
      // }
      return true
    })
    const currentImageUrl = computed(() => {
      if (currentPost.value && currentPost.value.imageUrl) {
        const { imageUrl } = currentPost.value
        return (imageUrl as string).split(',')
      } else {
        return null
      }
    })
    const hideAndDelete = () => {
      modalIsVisible.value = false
      store.dispatch('deletePost', currentId).then((rawData) => {
        createMessage('删除成功，2秒后跳转到专栏首页', 'success', 2000)
        setTimeout(() => {
          router.push({
            name: 'column'
          })
        }, 2000)
      })
    }
    return {
      currentPost,
      currentImageUrl,
      currentHTML,
      showEditArea,
      modalIsVisible,
      hideAndDelete
    }
  }
})
