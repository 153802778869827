
import { computed, defineComponent, PropType } from 'vue'
import { ImageProps, PostProps } from '../store'
import { generateFitUrl } from '../helper'

export default defineComponent({
  props: {
    list: {
      required: true,
      type: Array as PropType<PostProps[]>
    }
  },
  setup(props) {
    const posts = computed(() => {
      return props.list.map(post => {
        generateFitUrl(post.imageUrl as ImageProps, 200, 110, ['m_fill'])
        return post
      })
    })
    return {
      posts
    }
  }
})
