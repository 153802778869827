
import { defineComponent, computed, onMounted, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { GlobalDataProps, ColumnProps } from '../store'
import PostList from '../components/PostList.vue'
import { addColumnAvatar } from '../helper'
export default defineComponent({
  components: {
    PostList
  },
  setup() {
    const route = useRoute()
    const store = useStore<GlobalDataProps>()
    // 为了让 computed 响应对应的变化，添加响应式对象
    const currentId = ref(1)
    onMounted(() => {
      // store.dispatch('fetchColumn', currentId.value)
      store.dispatch('fetchPosts', currentId.value)
    })
    // 检测变化
    watch(() => route.params, (toParams) => {
      debugger
      console.log('返回到列表页===========')
      // const jumpId = toParams && toParams.id
      // const column = store.state.user.column
      // console.log('jumpId==' + jumpId)
      // console.log('column==' + column)
      // if (jumpId && column && (jumpId === column)) {
      //   // 重新发送请求，在 store 中有对应的缓存设置
      //   store.dispatch('fetchColumn', jumpId)
      //   store.dispatch('fetchPosts', { cid: jumpId })
      //   // 重新赋值，这样 computed 会变化
      //   currentId.value = toParams.id
      // }
      store.dispatch('fetchPosts', { cid: currentId.value })
    })
    const column = computed(() => {
      const selectColumn = store.getters.getColumnById(currentId.value) as ColumnProps | undefined
      if (selectColumn) {
        addColumnAvatar(selectColumn, 100, 100)
      }
      return selectColumn
    })
    const list = computed(() => {
      console.log('currentId=' + currentId.value)
      const list = store.getters.getPostsByCid(currentId.value)
      console.log('list=' + list)
      return list
    }
    )

    return {
      column,
      list
    }
  }
})
