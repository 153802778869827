
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Options } from 'easymde'
import { GlobalDataProps, ImageProps, PostProps, ResponseType } from '../store'
import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
import ValidateForm from '../components/ValidateForm.vue'
import Editor from '../components/Editor.vue'
import createMessage from '../components/createMessage'
import { beforeUploadCheck } from '../helper'
import { Plus } from '@element-plus/icons-vue'

import type { UploadFile, UploadFiles, UploadProps, UploadUserFile } from 'element-plus'

export default defineComponent({
  name: 'CreatePost',
  components: {
    ValidateInput,
    ValidateForm,
    Editor,
    Plus
  },
  setup() {
    const titleVal = ref('')
    const editorStatus = reactive({
      isValid: true,
      message: ''
    })
    const router = useRouter()
    const route = useRoute()
    const isEditMode = !!route.query.id
    const store = useStore<GlobalDataProps>()
    const textArea = ref<null | HTMLTextAreaElement>(null)
    const editorOptions: Options = {
      spellChecker: false
    }
    const data = {
      fileType: 'image',
      userId: '1'
    }
    const titleRules: RulesProp = [
      {
        type: 'required',
        message: '文章标题不能为空'
      }
    ]
    const contentVal = ref('')
    const contentRules: RulesProp = [
      {
        type: 'required',
        message: '文章详情不能为空'
      }
    ]
    const checkEditor = () => {
      if (contentVal.value.trim() === '') {
        editorStatus.isValid = false
        editorStatus.message = '文章详情不能为空'
      } else {
        editorStatus.isValid = true
        editorStatus.message = ''
      }
    }

    const fileList = ref<UploadUserFile[]>([])

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)

    const handleRemove: UploadProps['onRemove'] = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles)
    }

    const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!
      dialogVisible.value = true
    }
    onMounted(() => {
      if (isEditMode) {
        store.dispatch('fetchPost', route.query.id).then((rawData: ResponseType<PostProps>) => {
          const currentPost = rawData.data
          debugger
          if (currentPost.imageUrl) {
            const array = (currentPost.imageUrl as string).split(',')
            array.forEach((value, index, array) => {
              fileList.value.push({
                name: index.toString(),
                url: value
              })
            })
          }
          titleVal.value = currentPost.title
          contentVal.value = currentPost.desc || ''
        })
      }
    })
    const handleFileUploaded = (rawData: ResponseType<ImageProps>, uploadFile: UploadFile, uploadFiles: UploadFiles) => {
      if (rawData.code === '00000000') {
        uploadFile.url = rawData.data.url
      } else {
        // 失败
      }
    }
    const onFormSubmit = (result: boolean) => {
      debugger
      checkEditor()
      if (result && editorStatus.isValid) {
        const {
          column,
          _id
        } = store.state.user
        if (column) {
          const newPost: PostProps = {
            title: titleVal.value,
            desc: contentVal.value,
            column,
            author: _id
          }
          if (fileList.value.length > 0) {
            let url = ''
            fileList.value.forEach((value) => {
              url = url + value.url + ','
            })
            if (url.endsWith(',')) {
              url = url.substring(0, url.length - 1)
            }
            newPost.imageUrl = url
          }
          const actionName = isEditMode ? 'updatePost' : 'createPost'
          const id = route.query.id
          const sendData = isEditMode ? { contentId: route.query.id, ...newPost } : newPost
          store.dispatch(actionName, sendData).then(() => {
            createMessage('发表成功，2秒后跳转到文章', 'success', 2000)
            setTimeout(() => {
              debugger
              router.push({
                name: 'column',
                params: { id: column }
              })
            }, 2000)
          })
        }
      }
    }
    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, {
        format: ['image/jpeg', 'image/png'],
        size: 1
      })
      const {
        passed,
        error
      } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 1Mb', 'error')
      }
      return passed
    }
    return {
      titleRules,
      titleVal,
      contentVal,
      contentRules,
      onFormSubmit,
      uploadCheck,
      handleFileUploaded,
      isEditMode,
      textArea,
      editorOptions,
      checkEditor,
      editorStatus,
      handleRemove,
      handlePictureCardPreview,
      fileList,
      dialogVisible,
      dialogImageUrl,
      data
    }
  }
})
