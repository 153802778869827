import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e5bd9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-list" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  key: 0,
  class: "row my-3 align-items-center"
}
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
      return (_openBlock(), _createElementBlock("article", {
        key: post._id,
        class: "card mb-3 shadow-sm"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, [
            _createVNode(_component_router_link, {
              to: `/posts/${post._id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(post.title), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          (post.imageUrl )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(post.imageUrl.split(','), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item as string,
                    class: "col-4"
                  }, [
                    _createElementVNode("img", {
                      alt: item as string,
                      src: item as string,
                      class: "rounded-lg w-100"
                    }, null, 8, _hoisted_4)
                  ]))
                }), 128)),
                _createElementVNode("p", {
                  class: _normalizeClass([{'col-8': post.imageUrl}, "text-muted"])
                }, _toDisplayString(post.excerpt), 3)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h6", null, _toDisplayString(post.desc), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(post.createdAt), 1)
        ])
      ]))
    }), 128))
  ]))
}